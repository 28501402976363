<template>
  <div class="appraisal-view">
    <tojoy-title-content title="绩效考评">
      <template #footer>
        <div class="tojoy-title-content__footer">
          <tojoy-tabs v-model="tabName" :tabs="tabs" @tab-click="handleTabClick" />
        </div>
      </template>
    </tojoy-title-content>
    <div
      class="organize"
      :class="receivedData.length > 0 ? 'mb20' : ''"
      v-show="tabName === 'review'"
    >
      <tojoy-pagination-list
        :data="receivedData"
        :total="total"
        :size="size"
        @page-change="handleChangePage"
        :loading="loading"
      >
        <template #item="{data}">
          <tojoy-card :data="data" schema="people">
            <template #downcard>
              <review-item-card type="review" :data="data" />
            </template>
          </tojoy-card>
        </template>
      </tojoy-pagination-list>
    </div>
    <div class="organize" v-show="tabName === 'evaluation'">
      <tojoy-pagination-list
        :data="completeData"
        :total="peopleTotal"
        :size="peopleSize"
        @page-change="handlePeopleChangePage"
        :loading="loading"
      >
        <template #item="{data}">
          <tojoy-card :data="data" schema="people">
            <template #downcard>
              <review-item-card tip="tip" type="evaluation" :data="data">
                <template #default>
                  <span
                    >,S*{{ data.levelList.S }} A*{{ data.levelList.A }} B*{{
                      data.levelList.B
                    }}
                    C*{{ data.levelList.C }}</span
                  ></template
                >
              </review-item-card>
            </template>
          </tojoy-card>
        </template>
      </tojoy-pagination-list>
    </div>
  </div>
</template>
<script>
import { Message as ElMessage } from 'element-ui'
import TojoyTitleContent from '@/components/common/initiate-team/title-content'
import TojoyCard from '@/components/business/card'
import TojoyPaginationList from '@/components/business/pagination-list'
import { appraisalTabNames } from '@/components/organizational/state.js'
import reviewItemCard from '@/components/organizational/review-item-card'
import TojoyTabs from '@/components/business/tabs'
import { waitMeAppraisal, pastEvaluation } from '@/api/api_organization'
export default {
  name: 'appraisal',
  components: {
    TojoyPaginationList,
    TojoyCard,
    TojoyTitleContent,
    reviewItemCard,
    TojoyTabs
  },
  props: {
    moduleName: {
      type: String,
      default: 'all'
    }
  },
  data() {
    return {
      page: 1,
      size: 10,
      total: 0,
      peoplePage: 1,
      peopleSize: 20,
      peopleTotal: 0,
      receivedData: [],
      completeData: [],
      tabs: appraisalTabNames,
      tabName: this.moduleName,
      loading: false
    }
  },
  mounted() {
    let obj = {
      review: 0,
      evaluation: 1
    }
    this.tabIndex = obj[this.moduleName]
    this.loading = true
    if (this.tabIndex) {
      this.getPastEvaluation()
    } else {
      this.getWaitMeAppraisal()
    }
  },
  methods: {
    /**
     * 待我考评
     */
    getWaitMeAppraisal() {
      let param = {
        page: this.page,
        size: this.size
      }
      waitMeAppraisal(param)
        .then(res => {
          if (res.data) {
            let { waitList } = res.data
            this.receivedData = waitList
            this.receivedData.forEach(el => {
              el.sum = el.count
            })
          }
          this.loading = false
        })
        .catch(err => err)
    },
    /**
     * 过往考评
     */
    getPastEvaluation() {
      let params = {
        page: this.peoplePage,
        size: this.peopleSize
      }
      pastEvaluation(params)
        .then(res => {
          let { list, total } = res.data
          this.completeData = list
          this.peopleTotal = total
          list.map(el => {
            let obj = {
              S: 0,
              A: 0,
              B: 0,
              C: 0
            }
            for (let key in obj) {
              let rs = el.levs.find(el => el.pfLevel === key)
              obj[key] = rs?.count ?? 0
            }
            el.levelList = obj
            return el
          })
          this.loading = false
        })
        .catch(err => {
          console.log(err)
        })
    },
    handleChangePage(val) {
      this.page = val
      this.loading = true
      this.getWaitMeAppraisal()
    },
    handlePeopleChangePage(val) {
      this.peoplePage = val
      this.loading = true
      this.getPastEvaluation()
    },
    handleTabClick({ index }) {
      this.tabIndex = index
      this.commentData = []
      this.receivedData = []
      this.loading = true
      if (index === '1') {
        this.peoplePage = 1
        this.getPastEvaluation()
      } else {
        this.page = 1
        this.getWaitMeAppraisal()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.appraisal-view {
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .tojoy-pagination-list {
    flex: 1;
  }
  .pagination {
    margin: 0;
  }
  .organize {
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;
  }
  .mb20 {
    margin-bottom: 20px;
  }
  /deep/.errorPage {
    width: auto;
    margin-bottom: 20px;
  }
  /deep/.item-card {
    padding: 18px 20px 20px 15px;
    // margin-left: 15px;
  }
}
</style>
