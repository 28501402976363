<template>
  <div class="review-item-card" @click="ToPage(data)">
    <div class="review-left">
      <div class="title">{{ data.name }}</div>
      <div class="date">{{ data.date | formateDate }}</div>
      <div class="status">
        <span :class="[3, 4].includes(data.type) ? 'red' : ''">{{ data.sum | formateVal(data.type) }}</span>
        <slot></slot>
      </div>
      <!-- <div class="status">试用期员工及离职员工</div> -->
    </div>
    <div class="review-right">
      <span class="evaluation" :class="tip ? 'tip' : ''">{{ tip ? '已评价' : '待评价' }}</span>
      <i class="el-icon-arrow-right icon"></i>
    </div>
  </div>
</template>

<script>
import { MessageBox, Message } from 'element-ui'
import { evalOrg, remind } from '@/api/api_organization'
export default {
  name: 'review-item-card',
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    tip: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'evaluation'
    }
  },
  filters: {
    formateVal(val = 0, type) {
      //1 组织  2个人 3离职员工 4组织负责人
      if (type === 1) {
        return `参评组织${val}个`
      } else if (type === 2) {
        return `参评人员${val}人`
      } else if (type === 3) {
        return `单独考评${val}人`
      } else {
        return `单独考评${val}人`
      }
    }
  },
  methods: {
    ToPage({ type: code, orgId, id, date, name, sum, cfgId, levelList }) {
      console.log('111111111', { type: code, orgId, id, date, name, sum, cfgId, levelList })
      console.log(this.type, '222', code)
      if (this.type === 'review') {
        let obj = {
          1: 'organizeEvaluation',
          2: 'personalEvaluation',
          3: 'probationDepartureResult',
          4: 'probationDepartureResult'
        }
        if (code == '1') {
          // code=1 组织类型 {date: 202205, orgId: 11, eocId: 212}
          let pm = {
            date: date,
            eocId: id
          }
          this.getTopOrgList(pm).then(res => {
            console.log('org接口返回参数', res.data)
            if (res.data.levels == 0) {
              if (res.data.rparCfgRes == 2) {
                MessageBox.confirm('后台绩效等级占比配置设置不完整，暂不能考评', '提示', {
                  confirmButtonText: `确定`,
                  showCancelButton: false,
                  type: 'warning'
                }).then(() => {
                  console.log('后台绩效等级占比配置设置不完整，暂不能考评')
                })
              } else if (res.data.rparCfgRes == 1) {
                // 与绩效达成率强关联；
                let year = String(date)
                  .split('')
                  .slice(0, 4)
                  .join('')
                let month = String(date)
                  .split('')
                  .slice(4)
                  .join('')
                MessageBox.confirm(`${name}${year}年${month}月绩效达成率暂未维护，暂不能考评！快提醒管理员尽快维护吧！`, '提示', {
                  confirmButtonText: `提醒`,
                  cancelButtonText: '取消',
                  type: 'warning'
                })
                  .then(() => {
                    remind(pm).then(res => {
                      if (res.code === '000000') {
                        this.$msgSuccess('操作成功')
                      }
                    })
                  })
                  .catch(() => {
                    Message.info(`已取消操作`)
                  })
              }
            } else {
              // console.log('正常跳转')
              this.$router.push({
                name: obj[code],
                query: {
                  type: code,
                  orgId: code === 4 ? id : orgId,
                  id: id,
                  date: date,
                  name: name,
                  cfgId: cfgId,
                  count: sum || 0
                }
              })
            }
          })
        } else {
          //待我考评
          this.$router.push({
            name: obj[code],
            query: {
              type: code,
              orgId: code === 4 ? id : orgId,
              id: id,
              date: date,
              name: name,
              cfgId: cfgId,
              count: sum || 0
            }
          })
        }
      } else {
        //过往考评
        //personalResult 个人
        //organizeResult 组织
        let path = code === 1 ? 'organizeResult' : 'personalResult'
        this.$router.push({
          name: path,
          query: {
            orgId: id,
            date: date,
            name: name,
            levelList: JSON.stringify(levelList)
          }
        })
      }
    },
    async getTopOrgList(params) {
      return await evalOrg(params)
    }
  }
}
</script>

<style lang="scss" scoped>
.review-item-card {
  display: flex;
  align-items: center;
  cursor: pointer;
  .review-left {
    flex: 1;
    .title {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: $lightblack;
      line-height: 18px;
      margin-bottom: 15px;
    }
    .date {
      font-size: 12px;

      font-weight: 400;
      color: $lightblack;
      line-height: 12px;
      margin-bottom: 15px;
    }
    .status {
      font-size: 12px;

      font-weight: 400;
      color: $grey;
      line-height: 12px;
      // margin-bottom: 21px;
    }
    .departure {
      color: $red;
    }
  }
  .review-right {
    flex: none;
    text-align: right;
    .evaluation {
      font-size: 13px;
      font-weight: 400;
      color: $blue;
      line-height: 13px;
    }
    .tip {
      color: $grey;
    }
    .icon {
      font-size: 13px;
      margin-left: 7px;
      color: #aaaaaa;
    }
  }
  .red {
    color: $red;
  }
}
</style>
